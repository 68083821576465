<template>
  <div class="page">
    <router-view />
    <van-tabbar route active-color="#fff" style='background-color: #16263e;'>
      <van-tabbar-item to="/index" icon="home-o">
        <span>{{ $t('common.home') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.sy.active : icon.sy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/news" icon="search"
        ><span>{{ $t('common.news') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.hq.active : icon.hq.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/trade" icon="friends-o">
        <span>{{ $t('common.trade') }}</span>
        <template #icon="props">
          <img :src="props.active ? icon.jy.active : icon.jy.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/mine" icon="setting-o">
        <span>{{ $t('common.assets') }}</span>
        <template #icon="props">
          <img
            :src="props.active ? icon.zc.active : icon.zc.inactive"
          /> </template
      ></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      icon: {
        sy: {
          active: require('../assets/img/sy-xz.png'),
          inactive: require('../assets/img/sy.png')
        },
        hq: {
          active: require('../assets/img/hq-xz.png'),
          inactive: require('../assets/img/hq.png')
        },
        jy: {
          active: require('../assets/img/jy-xz.png'),
          inactive: require('../assets/img/jy.png')
        },
        zc: {
          active: require('../assets/img/mine-xz.png'),
          inactive: require('../assets/img/mine.png')
        }
      }
    }
  },
  methods: {}
}
</script>
<style lang="less" scoped>
.page {
  background: #0b1622;
}
/deep/ .van-hairline--top-bottom:after,
/deep/ .van-hairline-unset--top-bottom:after {
  border-width: 0;
}
.van-tabbar {
  height: 3.33333rem;
  background-color: #000;
  //border-top: 0.02667rem solid #323a5e;
  z-index: 99;
  .van-tabbar-item__icon {
    margin-top: 5px;
  }
  .van-tabbar-item__text {
    color: #bbb;
  }
  .van-tabbar-item img {
    width: 1.6rem;
    height: 1.6rem;
  }
  .van-tabbar-item--active {
    color: #fff;
    background-color: #16263e;
  }
  .van-hairline-unset--top-bottom::after {
    border-width: 0px 0;
  }
}

</style>
